import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import moment from 'moment';

import HiddenSpan from 'components/HiddenSpan';
import { FlexRowCenter } from 'components/flex-box';

import { Box, BoxProps, Button, ButtonBase, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const OpusOneEvtButton: React.FC<BoxProps> = (props: BoxProps) => {
  const [showQrCode, setShowQrCode] = React.useState<boolean>(false);

  const handleClick = () => {
    if (isAndroid || isIOS) {
      window.open('https://wineone.page.link/opusone24');
    } else {
      // PC 사용자일 경우 QR 띄우기
      setShowQrCode(true);
    }
  };
  const nowMmt = moment.utc();

  const startMmt = moment.utc('24112610', 'YYMMDDHH');
  const endMmt = moment.utc('24113022', 'YYMMDDHH');

  // 오퍼스원 행사기간 아님
  if (!nowMmt.isBetween(startMmt, endMmt)) {
    return null;
  }

  // render
  return (
    <>
      <Box {...props}>
        <ButtonBase
          sx={{
            borderRadius: '8px',
            width: '100%',
            overflow: 'hidden'
          }}
          onClick={handleClick}
        >
          <Box
            component="img"
            src="/static/images/banner/opusone/banner-btn.png"
            alt="오퍼스원 이벤트 바로가기"
            sx={{
              width: '100%',
              objectFit: 'contain',
              objectPosition: 'center center'
            }}
          />
          <HiddenSpan>앱에서 오퍼스원 할인행사를 확인해보세요</HiddenSpan>
        </ButtonBase>
      </Box>

      {/* 앱의 와인픽스 특가화면 이동 QR 다이어로그 */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showQrCode}
        onClose={() => {
          setShowQrCode(false);
        }}
      >
        <DialogContent>
          <FlexRowCenter my={2.5} flexDirection="column">
            <Box component="img" src="/static/images/banner/opusone/qr.png" width={180} height={180} />
            <Typography textAlign="center" letterSpacing="-0.3px" sx={{ mt: 1.5 }}>
              핸드폰의 카메라 기능을 사용하여 QR코드를 확인해보세요.
              <br />
              앱의 오퍼스원 이벤트 페이지로 이동할 수 있습니다.
            </Typography>
          </FlexRowCenter>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="darkGray"
            size="large"
            disableElevation
            onClick={() => {
              setShowQrCode(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OpusOneEvtButton;
