import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import HiddenSpan from 'components/HiddenSpan';
import { FlexRowCenter } from 'components/flex-box';

import {
  Box,
  BoxProps,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

const WelcomeCouponButton: React.FC<BoxProps> = (props: BoxProps) => {
  const theme = useTheme();
  // 화면사이즈가 sm보다 작을 때
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showQrCode, setShowQrCode] = React.useState<boolean>(false);

  const handleClick = () => {
    if (isAndroid || isIOS) {
      window.open('https://wineone.page.link/welcome_web');
    } else {
      // PC 사용자일 경우 QR 띄우기
      setShowQrCode(true);
    }
  };

  // render
  return (
    <>
      <Box {...props}>
        <ButtonBase
          sx={{
            borderRadius: '8px',
            width: '100%',
            // height: isSmallScreen ? '56px' : '70px',
            overflow: 'hidden'
          }}
          onClick={handleClick}
        >
          <Box
            component="img"
            src="/static/images/wb_welcome_coupon.webp"
            alt="웰컴쿠폰 버튼"
            sx={{
              width: '100%',
              // height: 'auto',
              objectFit: 'contain',
              objectPosition: 'center center'
            }}
          />
          <HiddenSpan>1KMWINE 앱 다운로드하고 웰컴 쿠폰 받기!</HiddenSpan>
        </ButtonBase>
      </Box>

      {/* 웰컴쿠폰 이벤트 화면 이동 QR 다이어로그 */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showQrCode}
        onClose={() => {
          setShowQrCode(false);
        }}
      >
        <DialogContent>
          <FlexRowCenter my={2.5} flexDirection="column">
            <Box component="img" src="/static/images/welcome_web_qr_code.webp" width={180} height={180} />
            <Typography textAlign="center" letterSpacing="-0.3px" sx={{ mt: 1.5 }}>
              핸드폰의 카메라 기능을 사용하여 QR코드를 확인해보세요.
              <br />
              앱의 웰컴쿠폰 이벤트 화면으로 이동할 수 있습니다.
            </Typography>
          </FlexRowCenter>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="darkGray"
            size="large"
            disableElevation
            onClick={() => {
              setShowQrCode(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WelcomeCouponButton;
