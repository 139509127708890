import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

import React, { Fragment, startTransition } from 'react';
import { NextPage, GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';

import { jwtDecode } from 'jwt-decode';
import ImageViewer from 'react-simple-image-viewer';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';
import mysql, { Connection } from 'mysql2/promise';

import { useKakaoLoader } from 'react-kakao-maps-sdk';
import * as amplitude from '@amplitude/analytics-browser';

// import project
import { deepen } from 'lib';
import { PdataCategory } from 'models/pdata/Pdata.types';

import { useAuthContext } from 'contexts/AuthContext';
import useScriptRef from 'hooks/useScriptRef';
import { useBrowserLocation } from 'hooks/useBrowserLocation';
import { MYSQL_CONNECTION_OPTION } from 'utils/1kmwine-mysql';
import { logger } from 'utils/logger';
import { getSalesChart } from 'services/ProductService/product';

import SEO from 'components/SEO';
import Sticky from 'components/Sticky';
import Header from 'components/header/Header';
import { Footer1 } from 'components/footer';
import { FlexBetween, FlexBox, FlexRowCenter } from 'components/flex-box';
import VendorImage from 'components/vendor/VendorImage';
import Image from 'components/BazaarImage';

import {
  CardTitle,
  MainCurationCard,
  MainJumbotron,
  NaraCampaignCard,
  SalesChartCard,
  SelfPromotionCard
} from 'pages-sections/home';

// import mui
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItemButton,
  Pagination,
  Rating,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Grid from '@mui/material/Unstable_Grid2';
import { PdataImage } from '../src/components/pdata';
import HiddenSpan from '../src/components/HiddenSpan';
import { SESSION_TOKEN } from '../src/config';
import ReviewLikeButton from '../src/components/buttons/ReviewLikeButton';
import OpusOneEvtButton from '../src/components/buttons/OpusOneEvtButton';
import WelcomeCouponButton from '../src/components/buttons/WelcomeCouponButton';
import Ws100EvtButton from '../src/components/buttons/Ws100EvtButton';

type IndexPageProps = {
  salesChart: {
    sales_record: number;
    _id: string;
    code: string;
    category: PdataCategory;
    name: { ko: string; en: string };
    price_min: number | null;
  }[];
  campaigns: any[];
  reviews: { writer: { nickname: string } } & any[];
};

const initialNearBy = Object.freeze({ loaded: false, distance: 1, list: [] });

/**
 * 인덱스 페이지
 */
const IndexPage: NextPage = ({ campaigns, salesChart, reviews }: IndexPageProps) => {
  const scriptedRef = useScriptRef();
  const router = useRouter();

  const { state, openSignInModal } = useAuthContext();
  const geolocation = useBrowserLocation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  // 사용자 주변 와인샵
  const [nearByShop, setNearByShop] = React.useState<{ loaded: boolean; distance: number; list: any[]; error?: any }>(
    initialNearBy
  );

  /** 주변 입점샵 찾기 */
  const fetchNearByVendors = async () => {
    if (geolocation.state === 'denied') {
      setNearByShop({ ...initialNearBy, loaded: true });
      return;
    }

    // 이미 주변 샵이 검색됨
    if (nearByShop.loaded) return;

    if (geolocation.state === 'granted') {
      setNearByShop(initialNearBy);
      // 좌표 최신화
      const coord = await geolocation.requestCurrentLocation();
      // 테스트 좌표
      // const coord = { latitude: 37.6469895476655, longitude: 126.914875303334 }; // 자몽마켓
      // const coord = { latitude: 40.6469895476655, longitude: 126.914875303334 };
      // console.log('--> 주변 샵 좌표 조회 시작: ', coord);
      const result = await requestNearByVendors(coord, nearByShop.distance);
      // console.log('# 주변 샵 조회결과: ', result);
      if (!scriptedRef.current) {
        console.warn('[IndexPage][fetchNearByVendors] Unmounted component.');
        return;
      }

      // 주변샵 조회 요청실패
      if (result.error) {
        setNearByShop((prev) => ({ ...prev, loaded: true, error: result.error, list: [] }));
        return;
      }

      setNearByShop((prev) => ({ ...prev, loaded: true, error: result.error, list: result.vendors }));
    } else {
      console.log('브라우저 위치정보 권한 확인필요', geolocation);
      setNearByShop((prev) => ({ ...prev, loaded: true, list: [] }));
    }
  };

  React.useEffect(() => {
    fetchNearByVendors();
  }, [nearByShop.loaded, geolocation.initialized, geolocation.state, state.isLoggedIn]);

  const CPG_PAGE_SIZE: number = React.useMemo(() => {
    return isSmallScreen ? 1 : 3;
    // return 1;
  }, [isSmallScreen]);

  // 와인픽스 캠페인 더보기 page
  const [campaignPage, setCampaignPage] = React.useState<number>(1);
  const totalCampaignPages = React.useMemo(() => {
    return Math.ceil(campaigns.length / CPG_PAGE_SIZE);
  }, [campaigns]);

  React.useLayoutEffect(() => {
    setCampaignPage(1);
  }, [campaigns]);

  // 리뷰 커서
  const [reviewCursor, setReviewCursor] = React.useState<number>(0);

  // 셀프 프로모션
  const selfPromotion = useSelfPromotion();
  const [loadingSelfPromotion, setLoadingSelfPromotion] = React.useState<boolean>(false);

  // 셀프 프로모션 더 불러오기
  const loadMoreSelfPromotion = async (page: number) => {
    setLoadingSelfPromotion(true);
    selfPromotion.setSize(page);
    setLoadingSelfPromotion(false);
  };

  const allContentsLoaded = React.useMemo(() => {
    // if (!selfPromotion.initialized) return false;

    return (
      selfPromotion.initialized &&
      (selfPromotion.page.total_pages === 0 || selfPromotion.size === selfPromotion.page.total_pages)
    );
  }, [selfPromotion.initialized, selfPromotion.size, selfPromotion.page?.current]);

  const mainCurations = useMainCurations(allContentsLoaded);

  const [scrollY, setScrollY] = React.useState<number>(window.pageYOffset);

  const onScroll = React.useCallback(() => {
    const { scrollY } = window;
    setScrollY(scrollY);
  }, []);

  React.useEffect(() => {
    if (window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight - window.innerHeight / 1.25) {
      if (selfPromotion.loading || loadingSelfPromotion) return;
      if (selfPromotion.page.total_pages <= 1) return;
      if (selfPromotion.page.total_pages === selfPromotion.page.current) return;

      // console.log('---- 다음 셀프 프로모션 불러오기', selfPromotion.page.current + 1);
      loadMoreSelfPromotion(selfPromotion.page.current + 1);
    }
  }, [scrollY]);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  // 리뷰 이미지 뷰어 오픈
  const [isReviewImgViewerOpen, setIsReviewImgViewerOpen] = React.useState<boolean>(false);
  const [currentImage, setCurrentImage] = React.useState<number>(0);

  const openImageViewer = React.useCallback((index: number) => {
    setCurrentImage(index);
    setIsReviewImgViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsReviewImgViewerOpen(false);
  };

  // render
  return (
    <Fragment>
      <SEO title="1KMWINE" description="와인과 일상을 잇다. 당신 주변의 와인. 1KMWINE" />
      {/* <Head> */}
      {/*  <meta name="robots" content="noindex,nofollow" />*/}
      {/* </Head> */}
      {/* HEADER */}
      <Sticky fixedOn={0}>
        <Header />
      </Sticky>
      <Box component="main" bgcolor="#edeff2" className="section-after-sticky" minWidth={300}>
        {/* 와인검색  */}
        <MainJumbotron />

        <Container fixed maxWidth="lg" sx={{ borderTop: `1px solid ${theme.palette.divider}`, pt: 2.5, pb: '40px' }}>
          <Grid container spacing={3.75} direction={isDownMd ? 'row' : 'row-reverse'}>
            <Grid xs={12} md={6} lg={4}>
              {isSmallScreen || state.isLoggedIn ? null : ( // <SessionCard />
                <Card sx={{ height: '80px', mb: 3.75 }}>
                  <CardContent>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{ height: '50px' }}
                      aria-label="1KMWINE 로그인"
                      onClick={() => openSignInModal()}
                    >
                      <Image
                        src="/assets/images/logo_lightgreen.webp"
                        alt="1KMWINE"
                        style={{ display: 'inline-block', height: '20px', marginBottom: '2px', marginRight: '4px' }}
                      />{' '}
                      <b style={{ letterSpacing: '-0.3px' }}>로그인</b>
                    </Button>
                  </CardContent>
                </Card>
              )}

              <Stack spacing={3.75}>
                {/* 주변 입점샵 (내 근처 와인샵) */}
                <NearVendorCard nearByShop={nearByShop} setNearByShop={setNearByShop} />

                {/* 오퍼스원 이벤트 todo 행사 종료 후 삭제 */}
                <OpusOneEvtButton />

                {/* 와인스펙테이터 이벤트 todo 행사 종료 후 삭제 */}
                <Ws100EvtButton />

                {/* 진행 중인 와인픽스 캠페인 */}
                {campaigns.map((campaign, i) => {
                  if (i + 1 > campaignPage * CPG_PAGE_SIZE) return null;
                  return <NaraCampaignCard key={`nara-campaign-${campaign.camp_cd}`} campaign={campaign} />;
                })}

                {campaigns.length > 1 && (
                  <>
                    {/* 더보기 / 접기 버튼 */}
                    {totalCampaignPages > 1 && campaignPage < totalCampaignPages ? (
                      <Button
                        variant="outlined"
                        color="info"
                        sx={{ bgcolor: 'background.paper' }}
                        onClick={() => {
                          setCampaignPage((prev) => prev + 1);
                        }}
                      >
                        와인픽스 특가 더보기
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="darkGray"
                        onClick={() => {
                          setCampaignPage(1);
                        }}
                      >
                        와인픽스 특가 접기
                      </Button>
                    )}
                  </>
                )}
              </Stack>
            </Grid>

            <Grid xs={12} md={6} lg={8}>
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  [theme.breakpoints.values.xs]: 1,
                  [theme.breakpoints.values.lg]: 2
                }}
              >
                <Masonry gutter="30px">
                  {/* 주간 판매순위 */}
                  <SalesChartCard salesChart={salesChart} />

                  {/* 최신 리뷰 */}
                  {reviews.length > 0 && (
                    <Card component="section">
                      <CardContent sx={{ pb: '20px !important' }}>
                        <CardTitle primary="유저의 리얼 리뷰" />
                        {/*<Typography variant="caption" fontSize={11} color="#707078">*/}
                        {/*  동일한 상품에 대해 작성된 최신 리뷰로, 판매샵은 다를 수 있습니다.*/}
                        {/*</Typography>*/}
                        <Box mt={1.5}>
                          <Card variant="outlined" sx={{ position: 'relative' }}>
                            <CardContent sx={{ p: 1.5, pb: '10px !important' }}>
                              <FlexBox>
                                <Box width={50} height={70} flexShrink={0}>
                                  <PdataImage
                                    thumb
                                    key={`review-img-${reviews[reviewCursor].pdata._id}`}
                                    id={reviews[reviewCursor].pdata._id}
                                    width="50px"
                                    height="70px"
                                    alt={reviews[reviewCursor].pdata.name.ko}
                                    py={1}
                                  />
                                </Box>
                                <FlexBox
                                  key={`review-dtl-${reviews[reviewCursor].pdata._id}`}
                                  justifyContent="center"
                                  flexDirection="column"
                                  ml="6px"
                                  flexGrow={1}
                                  maxWidth="calc(100% - 50px - 8px)"
                                >
                                  <Typography
                                    noWrap
                                    component="span"
                                    fontSize={14}
                                    fontWeight={600}
                                    lineHeight="20px"
                                    letterSpacing="-0.3px"
                                  >
                                    {reviews[reviewCursor].pdata.name.ko}
                                  </Typography>
                                  <Typography
                                    noWrap
                                    component="span"
                                    color="#707078"
                                    fontSize={12}
                                    lineHeight="14px"
                                    letterSpacing="-0.5px"
                                    sx={{ display: 'block' }}
                                  >
                                    {reviews[reviewCursor].pdata.name.en}
                                  </Typography>
                                  {reviews[reviewCursor].pdata.price.minimum !== null ? (
                                    <Typography
                                      component="span"
                                      color="#1e1e23"
                                      fontSize="14px"
                                      lineHeight="15px"
                                      letterSpacing="-0.3px"
                                      sx={{ mt: 1, display: 'block' }}
                                    >
                                      {reviews[reviewCursor].pdata.price.minimum.toLocaleString()}원 ~
                                    </Typography>
                                  ) : (
                                    <Typography
                                      component="span"
                                      color="lightGray"
                                      fontSize="12px"
                                      lineHeight="15px"
                                      letterSpacing="-0.3px"
                                      sx={{ mt: 1, display: 'block' }}
                                    >
                                      [품절]
                                    </Typography>
                                  )}
                                </FlexBox>
                              </FlexBox>
                              <ButtonBase
                                key={`review-pdata-btn-${reviews[reviewCursor].pdata._id}`}
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  height: '100%',
                                  width: '100%',
                                  borderRadius: '7px'
                                }}
                                onClick={() => {
                                  // amplitude
                                  if (process.env.NODE_ENV === 'production') {
                                    try {
                                      amplitude.track('Select Wine', {
                                        page: '[web] 홈 화면 - 리뷰',
                                        'pdata id': reviews[reviewCursor].pdata._id,
                                        'pdata name': reviews[reviewCursor].pdata.name.ko
                                      });
                                    } catch (e) {
                                      console.warn('Amplitude track error', e);
                                    }
                                  }
                                  router.push(`/product/${reviews[reviewCursor].pdata.code}`);
                                }}
                              >
                                <HiddenSpan>상품 페이지로</HiddenSpan>
                              </ButtonBase>
                            </CardContent>
                          </Card>
                          {reviews.length > 1 && (
                            <FlexBetween mt={1}>
                              <Button
                                color="info"
                                size="small"
                                disabled={reviewCursor === 0}
                                startIcon={<ArrowBackIosNewIcon />}
                                onClick={() => {
                                  startTransition(() => {
                                    setReviewCursor((prev) => prev - 1);
                                  });
                                }}
                                sx={{ ml: '-10px' }}
                              >
                                이전
                              </Button>
                              <Button
                                color="info"
                                size="small"
                                disabled={reviewCursor === reviews.length - 1}
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={() => {
                                  startTransition(() => {
                                    setReviewCursor((prev) => prev + 1);
                                  });
                                }}
                                sx={{ mr: '-10px' }}
                              >
                                다음
                              </Button>
                            </FlexBetween>
                          )}
                          <CardHeader
                            disableTypography
                            title={
                              <Stack direction="row" spacing={0.75} alignItems="center">
                                <Typography component="span" variant="subtitle2" lineHeight="16px">
                                  {reviews[reviewCursor].writer.nickname ?? '[탈퇴한 사용자]'}
                                </Typography>
                                <Typography variant="caption" color="#707078" lineHeight="16px">
                                  {moment(reviews[reviewCursor].created_at).fromNow()}
                                </Typography>
                              </Stack>
                            }
                            // titleTypographyProps={{ letterSpacing: 0, fontSize: '14px' }}
                            subheader={
                              <Rating
                                key={`review-rating-${reviews[reviewCursor].id}`}
                                name="리뷰 평점"
                                value={reviews[reviewCursor].star}
                                size="small"
                                readOnly
                                sx={{ fontSize: '14px' }}
                              />
                            }
                            sx={{ '& .MuiCardHeader-avatar': { marginRight: '10px' }, px: 0, pb: 0 }}
                          />
                          <Typography paragraph whiteSpace="pre-wrap" sx={{ mt: 1 }}>
                            {reviews[reviewCursor].content}
                          </Typography>

                          {/* 리뷰 이미지 있을 경우, 리뷰 이미지는 최대 5개 */}
                          {reviews[reviewCursor].images?.length > 0 && (
                            <Box my={1} key={`review-img-wrap-${reviews[reviewCursor].id}`}>
                              <Grid container columns={5} spacing={1}>
                                {reviews[reviewCursor].images.map(
                                  (img: { thumb: { url: string }; viewer: { url: string } }, i: number) => {
                                    // console.log('#img: ', img);
                                    return (
                                      <Grid key={`review-img-${reviews[reviewCursor].id}-${i}`} xs={1}>
                                        <Box display="inline-flex" position="relative" sx={{ aspectRatio: '1/1' }}>
                                          {/* 리뷰 이미지 썸네일 */}
                                          <Box
                                            component="img"
                                            alt={`리뷰이미지-${i + 1}`}
                                            src={img.thumb.url}
                                            sx={{ objectFit: 'cover', height: '100%', width: '100%' }}
                                          />
                                          {/* 리뷰 이미지 크게보기 버튼 */}
                                          <ButtonBase
                                            onClick={() => {
                                              openImageViewer(i);
                                            }}
                                            sx={{ position: 'absolute', width: '100%', height: '100%' }}
                                          >
                                            <HiddenSpan>이미지 크게보기</HiddenSpan>
                                          </ButtonBase>
                                        </Box>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Box>
                          )}

                          <Box ml="-5px" mb={1.5}>
                            <MemoizedReviewLikeButton
                              pdataId={reviews[reviewCursor].pdata._id}
                              reviewId={reviews[reviewCursor].id}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  )}

                  {/* 진행 중인 셀프 프로모션 */}
                  {selfPromotion?.list?.map((selfPromotion) => {
                    return <SelfPromotionCard key={`vp-promo-${selfPromotion._id}`} selfPromotion={selfPromotion} />;
                  })}

                  {/* 구글 광고 - 웹 메인 화면 */}
                  {/*<Box*/}
                  {/*  component="ins"*/}
                  {/*  className="adsbygoogle"*/}
                  {/*  display="block"*/}
                  {/*  data-ad-client="ca-pub-7233970740734143"*/}
                  {/*  data-ad-slot="1556148572"*/}
                  {/*  data-ad-format="auto"*/}
                  {/*  data-full-width-responsive="true"*/}
                  {/*/>*/}

                  {/* 웰컴쿠폰 이벤트 버튼 */}
                  <WelcomeCouponButton />

                  {/* 메인 큐레이션 */}
                  {mainCurations?.list?.map((curation: any, i) => {
                    return (
                      <React.Fragment key={`main-curation-${curation.id}`}>
                        <MainCurationCard curation={curation} />
                        {/*{i > 0 && i % 3 === 0 && (*/}
                        {/*  // 구글 광고 - 웹 메인 화면*/}
                        {/*  <Box*/}
                        {/*    component="ins"*/}
                        {/*    className="adsbygoogle"*/}
                        {/*    display="block"*/}
                        {/*    data-ad-client="ca-pub-7233970740734143"*/}
                        {/*    data-ad-slot="1556148572"*/}
                        {/*    data-ad-format="auto"*/}
                        {/*    data-full-width-responsive="true"*/}
                        {/*  />*/}
                        {/*)}*/}
                      </React.Fragment>
                    );
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </Grid>
          </Grid>
        </Container>
        <Footer1 />
      </Box>

      {/* 리뷰 이미지 보기 */}
      {isReviewImgViewerOpen && (
        <ImageViewer
          src={reviews[reviewCursor].images.map(({ viewer, original }) =>
            isDownMd ? (viewer ?? original).url : original.url
          )}
          // src={reviews[reviewCursor].images.map(({ viewer }) => viewer.url)}
          currentIndex={currentImage}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ zIndex: 1500 }}
        />
      )}
    </Fragment>
  );
};

const NearVendorCard = React.memo(({ nearByShop, setNearByShop }: any) => {
  const geolocation = useBrowserLocation();
  const router = useRouter();

  const [loadingKakao, kakaoMapError] = useKakaoLoader({
    appkey: `${process.env.NEXT_PUBLIC_KAKAO_APP_KEY}`
  });

  // 사용자 현위치 주소
  const [currLocationStr, setCurrLocationStr] = React.useState<string>('위치정보 확인 중...');

  const coord2Address = async () => {
    if (!geolocation.initialized || loadingKakao) {
      setCurrLocationStr('위치정보 확인 중...');
      return;
    }
    if (kakaoMapError) {
      setCurrLocationStr('[주소정보 확인 실패]');
      return;
    }
    if (geolocation.state !== 'granted') {
      setCurrLocationStr('[주변 매장을 확인하기 위해 위치 확인이 필요합니다]');
      return;
    }
    if (!geolocation.coords) {
      setCurrLocationStr('위치정보를 확인할 수 없습니다.');
      return;
    }

    try {
      const { latitude, longitude } = geolocation.coords;
      // console.log('# ---> ', latitude, longitude);
      const geocoder = new window.kakao.maps.services.Geocoder();
      const coords = new kakao.maps.LatLng(latitude, longitude);

      geocoder.coord2Address(
        coords.getLng(),
        coords.getLat(),
        (results: any, status: 'OK' | 'ERROR' | 'ZERO_RESULT') => {
          let addressStr: string = '[현위치 주소 확인불가]';

          if (status === 'OK') {
            // 도로명 있을 경우 우선 표현
            if (results?.[0]?.road_address?.address_name) {
              addressStr = results[0].road_address.address_name;
            }
            // 읍면동 주소
            else if (results?.[0]?.address?.address_name) {
              addressStr = results[0].address.address_name;
            }
          }

          setCurrLocationStr(addressStr);
        }
      );
    } catch (e) {
      console.error('현위치 주소 확인 실패', e);
      setCurrLocationStr('[주소를 알 수 없는 위치]');
    }
  };

  React.useEffect(() => {
    coord2Address();
  }, [geolocation.initialized, geolocation.state, geolocation.coords, loadingKakao, kakaoMapError]);

  const pageSize = 3;
  const [currPage, setCurrPage] = React.useState<number>(1);

  const [start, end] = React.useMemo(() => {
    const start = (currPage - 1) * pageSize;
    return [start, start + pageSize];
  }, [currPage]);

  // LIMIT ${(currPage - 1) * pageSize}, ${pageSize}

  // render: NearVendorCard
  return (
    <Card>
      <CardContent sx={{ pb: '12px !important' }}>
        <CardTitle primary="내 근처 와인샵" />
        <Typography variant="caption" color="#707078" letterSpacing="-0.3px">
          {nearByShop.loaded ? currLocationStr : `${nearByShop.distance}km 내 와인샵 찾는 중...`}
        </Typography>
        <Box mt={1} width="100%">
          {!nearByShop.loaded ? (
            <FlexRowCenter sx={{ height: 52 }}>
              <FlexBox width="100%">
                <Skeleton variant="rounded" sx={{ width: 36, height: 36 }} />
                <FlexBox width="calc(100% - 36px)" pl={1}>
                  <Box width="100%">
                    <Typography fontSize={14} lineHeight={'19px'} fontWeight={700}>
                      <Skeleton sx={{ width: 88 }} />
                    </Typography>
                    <Typography fontSize={10} sx={{ width: 135 }}>
                      <Skeleton />
                    </Typography>
                  </Box>
                </FlexBox>
              </FlexBox>
            </FlexRowCenter>
          ) : nearByShop.list.length > 0 ? (
            <Box>
              <List disablePadding>
                {nearByShop.list.slice(start, end).map((vendor) => {
                  // console.log('---> nearVendor:', vendor);

                  // todo 오늘 휴무여부
                  // todo 평균 10분 이내 응답

                  // '내 근처 와인샵' 상점목록
                  return (
                    <ListItemButton
                      key={`near-vendor-${vendor._id}`}
                      disableGutters
                      onClick={() => {
                        // console.log('## 상점 상세페이지로 이동', vendor._id);

                        if (process.env.NODE_ENV === 'production') {
                          try {
                            amplitude.track('Select Store', {
                              page: '[web] 홈 - 내 근처 와인샵 ',
                              'vendor id': vendor._id,
                              'vendor name': vendor.biz.name
                            });
                          } catch (e) {
                            console.warn('Amplitude track error', e);
                          }
                        }
                        router.push(`/vendor/${vendor._id}`);
                      }}
                    >
                      <FlexBox width="100%">
                        <VendorImage src={vendor.vendor_img?.[0]?.thumb} width={36} height={36} />
                        <FlexBox width="calc(100% - 36px)" pl={1}>
                          <Box width="100%">
                            <Typography fontSize={14} fontWeight={700}>
                              {vendor.biz.name}
                            </Typography>
                            <Typography noWrap fontSize={10} sx={{ opacity: 0.7 }}>
                              {vendor.biz.address1}
                              {vendor.biz.address2 ? `, ${vendor.biz.address2}` : null}
                            </Typography>
                          </Box>
                        </FlexBox>
                      </FlexBox>
                    </ListItemButton>
                  );
                })}
              </List>
              {Math.ceil(nearByShop.list.length / pageSize) > 1 && (
                <FlexRowCenter mt={1}>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={Math.ceil(nearByShop.list.length / pageSize)}
                    page={currPage}
                    onChange={(e, page) => {
                      setCurrPage(page);
                    }}
                  />
                </FlexRowCenter>
              )}
            </Box>
          ) : geolocation.state === 'granted' ? (
            <FlexRowCenter sx={{ height: 52 }}>
              <Typography variant="caption">{nearByShop.distance}km 내 1KMWINE샵이 없습니다.</Typography>
            </FlexRowCenter>
          ) : (
            <FlexRowCenter sx={{ height: 52 }}>
              <Button
                fullWidth
                variant="outlined"
                color="info"
                onClick={() => {
                  if (geolocation.state === 'denied') {
                    window.alert('브라우저에서 직접 위치정보 권한을 허용해주세요.\n\n[위치정보 조회 차단됨]');
                  } else {
                    geolocation
                      .requestCurrentLocation()
                      .then(() => {
                        setNearByShop(initialNearBy);
                      })
                      .catch((e) => {
                        /* DO NOTHING */
                      });
                  }
                }}
              >
                위치정보 권한 허용하기
              </Button>
              {/*<Typography variant="caption">위치정보</Typography>*/}
            </FlexRowCenter>
          )}
        </Box>
      </CardContent>
    </Card>
  );
});

export default IndexPage;

async function requestNearByVendors(coords: { latitude: number; longitude: number }, distance: number = 1) {
  // console.log(`좌표(${coords.latitude}, ${coords.longitude})로부터 ${distance}km 내에 있는 매장 조회`)
  return await axios
    .get('/api/util/find-vendor-by-coord', { params: { lat: coords.latitude, lng: coords.longitude, d: distance } })
    .then((response) => response.data)
    .catch((e) => {
      console.log('# 주변 입점샵 조회요청 실패', e);
      return { error: e };
    });
}

const selfPromotionFetcher = async ([name, _current]: [string, number]) => {
  try {
    const { data } = await axios.post('/api/promotion/self/current', { page: { current: _current ?? 1, size: 6 } });
    return data;
  } catch (e) {
    console.error('[selfPromotionFetcher] 진행 중인 셀프 프로모션 목록조회 실패', e);
    return { error: e };
  }
};

const useSelfPromotion = () => {
  const { data, size, setSize, isLoading, isValidating, error } = useSWRInfinite(
    (index) => ['main_self_promotions', index + 1],
    selfPromotionFetcher
  );
  const list = data ? data.flatMap((item) => item.list) : [];

  return {
    initialized: !isLoading,
    isLoadingInitialData: !data && !error,
    page: data ? data[data.length - 1].page : { current: 1, size: 6 },
    list,
    loading: isLoading || isValidating,
    error,
    size,
    setSize
  };
};

const mainCurationFetcher = async () => {
  // console.log('-------------------> mainCurationFetcher');
  try {
    const { data } = await axios.get('/api/content/curation/main');
    return data;
  } catch (e) {
    console.error('[mainCurationFetcher] 메인 큐레이션 목록조회 실패', e);
    return { error: e };
  }
};

const useMainCurations = (show: boolean) => {
  const { data, error, mutate, isLoading, isValidating } = useSWR(show ? 'main_curations' : null, mainCurationFetcher, {
    revalidateOnFocus: false,
    // revalidateOnMount: false,
    dedupingInterval: 1000 * 60 * 3
  });

  return {
    ...(data ?? { list: [] }),
    loading: isLoading || isValidating,
    error,
    mutate
  };
};

type NaraCampaign = {
  id: string;
  camp_cd: string;
  camp_nm: string;
  camp_stt_date: string;
  camp_end_date: string;
  vendors?: any[];
};

const MemoizedReviewLikeButton = React.memo(
  ({ pdataId, reviewId }: { pdataId: string; reviewId: string }) => {
    return <ReviewLikeButton variant="text" pdataId={pdataId} reviewId={reviewId} />;
  },
  (prevProps, nextProps) => {
    return prevProps.pdataId === nextProps.pdataId && prevProps.reviewId === nextProps.reviewId;
  }
);

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let uid: string = ''; // 로그인 사용자 uid

  try {
    const { [SESSION_TOKEN]: token } = context.req.cookies;
    // 로그인한 사용자의 요청
    if (token) {
      const decoded = jwtDecode(token) as any;
      if (decoded?.sub) {
        uid = decoded.sub as string;
        logger.debug(`로그인 사용자 uid => ${uid}`);
      }
    }
  } catch (e) {}

  // 인기순위 가지고오기
  const salesChart = await getSalesChart();

  const connection: Connection = await mysql.createConnection(MYSQL_CONNECTION_OPTION);

  // 와인픽스 캠페인 목록
  const campaigns: any[] = [];
  // 최신 리뷰 목록
  let reviews: any[] = [];

  try {
    const currentCampaignListQuery = `
        select cpg.id, cpg.camp_cd, cpg.camp_nm, date_format(cpg.camp_stt_date, '%y-%m-%d %T') as camp_stt_date, date_format(cpg.camp_end_date, '%y-%m-%d %T') as camp_end_date
        from tb_campaign cpg
        where cpg.camp_stt_date <= now() and cpg.camp_end_date >= now()
        order by cpg.camp_stt_date desc, cpg.created_at desc
        limit 8 -- DB부하 방지
     `;
    // 현재 진행 중인 캠페인 목록
    const [rawCampaigns]: any[] = await connection.query({ sql: currentCampaignListQuery, timeout: 1500 });

    for (const rawCampaign of rawCampaigns) {
      const campaign: NaraCampaign = rawCampaign;

      // 캠페인 참여 중인 입점샵 목록 조회
      const campaignVendorQuery = `
          select ms_no
               , v._id
               , v.biz__name     as 'biz.name'
               , v.biz__address1 as 'biz.address1'
               , v.biz__address2 as 'biz.address2'
               , v.vendor_img
          from (select ms_no
                from tb_campaign_goods cgds
                where cgds.camp_cd = ?
                group by cgds.ms_no) cv
                   inner join tb_vendor v on cv.ms_no = v.nara_pos_vendor_id
          where v.nara_pos_flag = 'true'
            and v.closed = 'false'
            and v.test = 'false'
      `;
      const [rawVendorList]: any[] = await connection.query({ sql: campaignVendorQuery, timeout: 1000 }, [
        rawCampaign.camp_cd
      ]);
      if (rawVendorList.length === 0) continue;

      campaign.vendors = [];

      // console.log('#msNoList: ', rawVendorList);
      // 입점샵별 상품목록 조회
      for (const rawVendor of rawVendorList) {
        const { ms_no } = rawVendor;
        const vendor: any = deepen(rawVendor);

        const campaignProductQuery = `
            select prd._id,
                   prd.name__ko as 'name.ko',
                   prd.name__en as 'name.en',
                   prd.vintage,
                   prd.capacity,
                   prd.price__original as 'price.original',
                   prd.price__sale     as 'price.sale',

                   prd.vendor__id      as 'vendor._id',
                
                   prd.pdata___id      as 'pdata._id',
                   pdat.category       as 'pdata.category',
                   pdat.type           as 'pdata.type',
                   pdat.country        as 'pdata.country',
                   pdat.variety        as 'pdata.variety'
            from tb_product prd
              inner join tb_vendor v on v._id = prd.vendor__id
              inner join tb_pdata pdat on pdat._id = prd.pdata___id
            where prd.available = 'true'
              and prd.campaign__camp_cd = ?
              and prd.quantity > 0
              and prd.\`remove\` = 'false'
              and v.nara_pos_flag = 'true'
              and v.nara_pos_vendor_id = ?
              and v.closed = 'false'
              and v.deleted = 'false'
            order by rand()
            limit 8
        `;

        const [rawCampaignProducts]: any[] = await connection.query({ sql: campaignProductQuery, timeout: 2000 }, [
          rawCampaign.camp_cd,
          ms_no
        ]);

        // 판매 중인 캠페인 상품이 없을 경우 건너뛰기
        if (rawCampaignProducts.length === 0) continue;

        vendor.products = rawCampaignProducts.map((rawProduct) => deepen(rawProduct));
        campaign.vendors.push(vendor);
      } // end of loop

      if (campaign.vendors.length > 0) {
        campaigns.push(campaign);
      }
    } // end of loop

    logger.debug(`조회된 캠페인 ${rawCampaigns.length}개`);

    // 최신 리뷰 조회 - 10개
    const latestReviewsQuery = `
        select rv.id
             , rv.pdata_id
             , rv.uid
             , rv.content
             , rv.vintage
             , rv.images
             , rv.like_cnt
             , rv.star
             , DATE_FORMAT(CONVERT_TZ(rv.created_at, @@global.time_zone, '+00:00'), '%Y-%m-%dT%H:%i:%s+00:00')  as created_at
             , if(rv.order_product_id is not null, true, false) as is_buyer  -- 구매자 리뷰 여부
             
             /* pdata 정보 */
             , pdat._id                                         as 'pdata._id'
             , pdat.\`code\`                                    as 'pdata.code'
             , pdat.\`name\`                                    as 'pdata.name'

             , pdat.star                                        as 'pdata.star'
             , pdat.reviews                                     as 'pdata.reviews'
             , pdat.wishes                                      as 'pdata.wishes'
             , price.price_min                                  as 'pdata.price.minimum'
             
             /* 리뷰 작성자 */
             , if(m.state != 'LEAVE', m.nickname, null)         as 'writer.nickname'
        
             /* 나의 좋아요 여부 */
             , if(rvl.id is not null, true, false)              as 'my_like'
        from tb_reviews rv
          inner join tb_pdata pdat on pdat._id = rv.pdata_id
          inner join (
              select pdata___id as pdata_id, min(price__original) price_min
              from tb_product prd
                inner join tb_vendor v on v._id = prd.vendor__id
              where prd.available = 'true'
                and prd.\`remove\` = 'false'
                and prd.quantity > 0
                and v.closed = 'false'
                and v.test = 'false'
              group by prd.pdata___id
          ) price on price.pdata_id = rv.pdata_id
          inner join tb_member m on m._id = rv.uid
          left join tb_reviews_like rvl on rvl.review_id = rv.id and rvl.uid = ?
        where rv.\`remove\` = 'false'
          and rv.\`show\` = 'true'
          and rv.star >= 2
          and rv.created_at >= DATE_SUB(NOW(), INTERVAL 1 MONTH)
          and pdat.\`show\` = 'true'
          and m.state != 'LEAVE'
        order by rv.created_at desc
        limit 10
    `;
    // console.log('# uid: ', uid);

    const [rawReviews]: any[] = await connection.query({ sql: latestReviewsQuery, timeout: 5000 }, [uid]).catch((e) => {
      logger.error('홈 화면 최신 리뷰 조회 오류', e.stack);
      return [];
    });

    reviews = rawReviews.map((rawReview) => {
      return deepen(rawReview);
    });

    logger.debug(`최신 리뷰 ${rawReviews.length}개 조회`);
  } catch (e) {
    logger.error('홈 화면 데이터 조회 실패', e);
  } finally {
    await connection.end();
    logger.debug('Connection ended.');
  }

  return { props: { salesChart, campaigns, reviews } };
}
